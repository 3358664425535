@import '../../../../scss/theme-bootstrap';

.page-header__nav {
  &__supplemental {
    width: 21%;
    @include breakpoint($medium-up) {
      float: none;
      margin-left: 135px;
    }
    @include breakpoint($landscape-up) {
      float: right;
      margin: 0;
    }
    @if is_cr20_desktop_nav_refresh() {
      @include breakpoint($medium-landscape-only) {
        width: 17.5%;
      }
    }
  }
}

.utility-nav-active,
.enable-endeca__search {
  .page-header,
  .page-header__nav {
    .gnav-util--search-v2.gnav-util.active-util {
      &.has-trustmark {
        .gnav-util__content__inner {
          form.gnav-search {
            input[type='search'] {
              z-index: 99;
              @include breakpoint($landscape-up) {
                left: 81%;
              }
              @include breakpoint($largest-up) {
                left: 77%;
              }
            }
          }
        }
      }
      .gnav-util__content {
        @include breakpoint($medium-up) {
          @if is_2020_nav_redesign() {
            border-top: 1px solid $color-2020-nav-mobile-border;
          } @else {
            border-top: 1px solid $color-black;
          }
          position: absolute;
        }
        @include breakpoint($landscape-up) {
          display: inline-table;
          display: -ms-grid;
          @if is_cr20_desktop_nav_refresh() {
            position: fixed;
          }
        }
        &__inner {
          background-color: transparent;
          @if not is_2020_nav_redesign() {
            margin-top: 54px;
          }
          @include breakpoint($medium-up) {
            margin-top: 26px;
          }
          form.gnav-search {
            background-color: $color-white;
            @include breakpoint($medium-up) {
              position: inherit;
            }
            @include breakpoint($landscape-up) {
              position: relative;
            }
            input[type='search'] {
              color: $color-black;
              z-index: 99;
              @include breakpoint($medium-up) {
                @if not is_cr20_desktop_nav_refresh() {
                  position: absolute;
                  #{$ldirection}: 19%;
                  top: -88px;
                }
              }
              @include breakpoint($landscape-up) {
                @if is_cr20_desktop_nav_refresh() {
                  margin: 0 0 0 15px;
                } @else {
                  margin-top: 0;
                  position: fixed;
                  #{$ldirection}: 80.5%;
                  top: 58px;
                }
              }
              .sticky-nav-active & {
                @if is_cr20_desktop_nav_refresh() {
                  @include breakpoint($landscape-up) {
                    top: 19px;
                  }
                }
              }
            }
            .gnav-search--main-content {
              display: block;
              overflow-y: scroll;
              @include breakpoint($medium-up) {
                overflow: hidden;
                min-height: 450px;
                width: 100%;
                position: -ms-device-fixed;
                background-color: inherit;
              }
              .gnav-search__close {
                @if is_2020_nav_redesign() {
                  display: none;
                  @include breakpoint($landscape-up) {
                    display: block;
                  }
                }
                .icon--close {
                  display: none;
                  @include breakpoint($medium-up) {
                    display: block;
                    padding: 5px 10px;
                    position: absolute;
                    right: 0;
                    font-size: 30px;
                  }
                  &:before {
                    display: block;
                  }
                }
              }
              .gnav-search__content {
                @include breakpoint($medium-up) {
                  float: right;
                  width: 25%;
                  display: block;
                  padding: 0px;
                  clear: none;
                }
              }
              .gnav-search__suggestions-wrapper {
                @include breakpoint($medium-up) {
                  width: 75%;
                  clear: none;
                  float: left;
                }
                border-right: 1px solid $color-light-gray;
                .mobile-looking-for {
                  @include breakpoint($medium-up) {
                    padding: 10px;
                    text-transform: uppercase;
                    font-family: $font--futura-demi;
                    font-size: 14px;
                    letter-spacing: 0.75px;
                    display: inline-block;
                    width: 83%;
                    margin: 0 auto;
                    position: relative;
                    left: 8%;
                  }
                }
                .product-grid__content {
                  flex-direction: row;
                  padding: 0;
                  margin: 0 auto;
                  display: block;
                  justify-content: space-around;
                  @include breakpoint($medium-up) {
                    display: flex;
                  }
                  .product-grid__item {
                    max-width: 375px;
                    display: inline-block;
                    flex: 1 1 auto;
                    padding: 10px 5px;
                    position: relative;
                    .product-brief {
                      padding: 10px 5px;
                      @include breakpoint($medium-up) {
                        padding: 4px 4px;
                      }
                      &__img {
                        margin: 0px;
                        max-width: 100px;
                        max-height: 100px;
                        width: 100%;
                        height: 100%;
                        @include breakpoint($medium-up) {
                          max-width: 339px;
                          max-height: 100%;
                          min-height: 339px;
                          margin: 0;
                          height: auto;
                        }
                        &--alt {
                          top: 4px;
                        }
                      }
                      &__detail {
                        .product-brief__subtitle {
                          @include breakpoint($medium-up) {
                            font-family: $font--futura-book;
                            margin-bottom: 10px;
                            display: inline-block;
                          }
                        }
                      }
                      &__header {
                        .product-brief__title {
                          font-family: $font--futura-demi;
                          font-size: 14px;
                          margin-bottom: 5px;
                          letter-spacing: 1px;
                        }
                        .product-brief__subline,
                        .product-brief__subtitle {
                          font-family: $font--futura-book;
                          text-transform: none;
                          font-size: 16px;
                          @include breakpoint($medium-up) {
                            font-family: $font--futura-book;
                            margin-bottom: 10px;
                            display: inline-block;
                          }
                        }
                      }
                    }
                  }
                }
                .gnav-search__suggestions {
                  margin: 0px;
                  padding: 15px 10px;
                  @include breakpoint($medium-up) {
                    padding: 0px 10px;
                    flex-direction: row;
                    width: 83%;
                    margin: 0 auto;
                  }
                }
                .typeahead-search__see-all-results {
                  visibility: visible;
                  height: auto;
                  text-transform: none;
                  border: 1px solid $color-drak-black;
                  padding: 5px;
                  @include breakpoint($medium-up) {
                    visibility: hidden;
                    height: 0px;
                  }
                  .typeahead-suggestions__item {
                    padding: 0px;
                    a.typeahead-search__suggestion {
                      @include cr18-btn-primary;
                      width: 100%;
                      height: 35px;
                    }
                  }
                }
              }
            }
            &.gnav-search--no-suggestions {
              .gnav-search--main-content {
                .gnav-search__no-suggestions-message {
                  display: none;
                }
              }
            }
          }
          footer.gnav-dropdown__footer {
            padding: 10px 0px;
            @include breakpoint($medium-up) {
              display: inline-grid;
              background-color: $color-light-gray;
              position: absolute;
            }
            @include breakpoint($medium-portrait-only) {
              width: 100%;
            }
            .gnav-dropdown__footer__items {
              .gnav-dropdown__footer__item {
                font-size: 14px;
                padding: 0px 10px;
                margin: 0;
                &:first-child {
                  border-right: 1px solid $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-header,
.page-header__nav {
  .utility-nav {
    width: auto;
    justify-content: center;
    text-align: center;
    float: none;
    margin-top: 3px;
    display: flex;
    margin: 3px 0px 0px 5px;
    @include breakpoint($xxsmall-up) {
      margin-left: 2px;
    }
    @include breakpoint($xxsmall-portrait) {
      margin-left: 6px;
    }
    @include breakpoint($medium-up) {
      float: right;
      @if is_2020_nav_redesign() {
        margin: 0 0 8px 0;
      }
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 3px 0 0 6px);
      float: none;
    }
    &__account-button {
      .utility-nav__signin-text {
        width: auto;
      }
      .utility-nav__account-text-link {
        color: $color-black;
        &:hover {
          color: $color-green;
        }
        @include breakpoint($landscape-up) {
          @if is_cr20_desktop_nav_refresh() {
            color: $color-cr18-black;
          } @else {
            color: $color-white;
          }
        }
      }
    }
  }
  .gnav-util--search-v2.gnav-util {
    max-width: 160px;
    width: 47%;
    @if is_2020_nav_redesign() {
      margin: 5px 0;
      max-width: none;
      width: 83%;
    }
    @include breakpoint($medium-up) {
      max-width: $gnav-search-input-width-v2;
      width: 100%;
    }
    @include breakpoint($landscape-up) {
      margin: 0;
    }
    &.has-trustmark {
      @include breakpoint($landscape-up) {
        max-width: 145px;
        width: 60%;
        float: left;
        .gnav-util__content-icon {
          width: 145px;
          margin-left: -15px;
        }
      }
      @include breakpoint($largest-up) {
        max-width: 190px;
        width: 70%;
        float: left;
        .gnav-util__content-icon {
          width: 190px;
        }
      }
      .gnav-util__content__inner {
        margin-top: 0;
        form.gnav-search {
          z-index: 7;
          input[type='search'] {
            min-width: 170px;
            @if is_2020_nav_redesign() {
              margin-top: 0;
            }
            @include breakpoint($landscape-up) {
              width: 115px;
              left: 16px;
            }
            @if is_2020_nav_redesign() {
              @include breakpoint($medium-landscape-only) {
                width: 140px;
                min-width: 170px;
              }
            }
            @include breakpoint($largest-up) {
              width: 160px;
            }
          }
        }
      }
    }
    .gnav-util__content-icon {
      @if is_2020_nav_redesign() {
        @include breakpoint($ipad-below-landscape) {
          display: none;
        }
      }
      align-items: center;
      @include breakpoint($medium-up) {
        margin-top: 0px;
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 99;
        left: 12.5%;
        width: 68%;
        @if is_2020_nav_redesign() {
          border-#{$ldirection}: 1px solid $color-2020-nav-mobile-border;
          height: 38px;
          top: 38px;
        } @else {
          top: 31px;
          height: 70px;
        }
      }
      @include breakpoint($landscape-up) {
        top: auto;
        width: $gnav-search-input-width-v2;
        position: relative;
        left: auto;
        z-index: auto;
        @if is_2020_nav_redesign() {
          border: none;
        }
        @if is_cr20_desktop_nav_refresh() {
          height: 45px;
        } @else {
          height: 70px;
        }
      }
      .sticky-nav-active & {
        @if is_cr20_desktop_nav_refresh() {
          @include breakpoint($landscape-up) {
            height: 63px;
          }
        }
      }
      @if is_cr20_desktop_nav_refresh() {
        @include breakpoint($medium-landscape-only) {
          width: $gnav-search-input-width-v2 - 30px;
        }
      }
      a.icon--search {
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        pointer-events: none;
        @if is_cr20_desktop_nav_refresh() {
          @if not is_2020_nav_redesign() {
            border: 1px solid $color-mid-gray;
          }
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 10px;
          }
          @include breakpoint($landscape-up) {
            margin-#{$ldirection}: 25px;
            border: none;
            border-bottom: 1px solid $color-mid-gray;
          }
          @include breakpoint($medium-landscape-only) {
            margin-#{$ldirection}: 10px;
          }
        } @else if is_2020_nav_redesign() {
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 10px;
          }
          @include breakpoint($landscape-up) {
            border: 1px solid $color-mid-gray;
            margin-#{$ldirection}: 5px;
          }
        } @else {
          border: 1px solid $color-mid-gray;
        }
        &:before {
          display: none;
        }
        svg {
          width: 21px;
          height: 20px;
          min-width: 21px;
          margin: 0 0 0 7px;
          @if is_cr20_desktop_nav_refresh() {
            @include breakpoint($landscape-up) {
              margin: 10px 0 0 -25px;
            }
          }
        }
      }
    }
    .gnav-util__content {
      opacity: 1;
      visibility: visible;
      padding: 0;
      border: 0;
      position: absolute;
      display: inline-table;
      top: $offerbar-height;
      position: absolute;
      display: inline-table;
      @if is_2020_nav_redesign() {
        @include breakpoint($ipad-below-landscape) {
          position: static;
          display: block;
        }
      }
      @include breakpoint($medium-up) {
        display: inline-table;
        right: 0;
        width: 100%;
        background-color: $color-white;
        top: auto;
        position: absolute;
        z-index: 99;
      }
      @if is_cr20_desktop_nav_refresh() {
        top: 0;
        @include breakpoint($landscape-up) {
          z-index: 9;
        }
      } @else {
        top: $offerbar-height;
      }
      @include breakpoint($medium-portrait-only) {
        top: 129px;
      }
      @include breakpoint($landscape-up) {
        display: block;
        position: static;
        top: auto;
      }
      @include breakpoint($medium-landscape-only) {
        top: auto;
      }
      &__inner {
        border: 0px;
        form.gnav-search {
          min-height: initial;
          padding: 0px;
          @include breakpoint($medium-up) {
            position: inherit;
            padding: 0;
          }
          @include breakpoint($landscape-up) {
            position: absolute;
            max-width: $max-width-large;
            margin: 0 auto;
            padding: 0 10px;
          }
          input[type='search'] {
            @include input-placeholder {
              font-family: $font--futura-book;
              font-size: 15px;
              color: $color-mid-gray;
            }
            min-width: 170px;
            font-family: $font--futura-book;
            margin-top: 2px;
            font-size: 15px;
            line-height: 30px;
            border: 0px;
            background: transparent;
            position: absolute;
            margin-left: 26%;
            width: 118px;
            top: -32px;
            color: $color-black;
            padding-left: 12px;
            @if is_2020_nav_redesign() {
              @include breakpoint($ipad-below-landscape) {
                position: static;
                left: 0;
                margin-left: 0;
                width: 80%;
                float: none;
                background: url(/sites/origins/themes/or_base/img/icons/src/search-v2.svg) no-repeat;
                background-size: 24px auto;
                background-position: 10px center;
                padding-left: 40px;
              }
              @include breakpoint($medium-up) {
                background: none;
                padding-#{$ldirection}: 20px;
              }
            }
            @include breakpoint($xxsmall-up) {
              width: 128px;
            }
            @include breakpoint($xsmall-up) {
              margin-left: 23%;
            }
            @include breakpoint($medium-up) {
              position: absolute;
              margin-left: 0;
              padding: 0;
              top: -88px;
              #{$ldirection}: 19%;
              width: 63%;
            }
            @include breakpoint($landscape-up) {
              padding: 0;
              height: 32px;
              @if is_cr20_desktop_nav_refresh() {
                width: $gnav-search-input-width-v2 - 25px;
                position: fixed;
                #{$ldirection}: auto;
                top: 88px;
                margin: 0 0 0 15px;
              } @else {
                width: $gnav-search-input-width-v2 - 35px;
                position: absolute;
                #{$ldirection}: 33px;
                top: -51px;
                margin: 0;
                margin-#{$ldirection}: 20%;
              }
            }
            @include breakpoint($medium-landscape-only) {
              top: -51px;
              @if is_cr20_desktop_nav_refresh() {
                width: $gnav-search-input-width-v2 - 40px;
              }
            }
            .sticky-nav-active & {
              @if is_cr20_desktop_nav_refresh() {
                @include breakpoint($landscape-up) {
                  top: 20px;
                }
              }
            }
          }
          .gnav-search--main-content {
            @include breakpoint($medium-up) {
              display: none;
            }
            .gnav-search__close {
              @if is_2020_nav_redesign() {
                display: none;
                @include breakpoint($landscape-up) {
                  display: block;
                }
              }
              .icon--close {
                display: none;
              }
            }
            .gnav-search__content {
              &__block--find-store {
                display: none;
              }
              .menuref {
                &:first-child {
                  width: 100%;
                  padding: 0 35px 25px;
                }
                &:nth-child(2) {
                  display: none;
                }
                &__title {
                  font-size: 14px;
                  text-transform: uppercase;
                  font-family: $font--futura-demi;
                  letter-spacing: 0.5px;
                  margin-bottom: 7px;
                  &:after {
                    margin: 0px;
                    height: 0px;
                  }
                }
                @include breakpoint($medium-up) {
                  &__content {
                    ul.menu__list {
                      margin-bottom: 25px;
                      li {
                        padding: 3px 0;
                        a.menu__link {
                          font-family: $font--futura-book;
                          font-size: 14px;
                          letter-spacing: 0.5px;
                          color: $color-black;
                        }
                      }
                    }
                  }
                }
              }
            }
            .gnav-search__no-suggestions-message {
              display: none;
            }
          }
        }
        footer.gnav-dropdown__footer {
          display: none;
        }
      }
    }
    .gnav-util__content__inner {
      form.gnav-search {
        input[type='search'].gnav-search__field {
          @include breakpoint($landscape-up) {
            @if is_cr20_desktop_nav_refresh() {
              top: 88px;
              @include swap_direction(margin, 0 0 0 15px);
            }
          }
          @if is_cr20_desktop_nav_refresh() {
            @include breakpoint($medium-landscape-only) {
              margin: 0;
            }
          }
          .sticky-nav-active & {
            @if is_cr20_desktop_nav_refresh() {
              @include breakpoint($medium-landscape-only) {
                top: 53px;
              }
              @include breakpoint($landscape-up) {
                top: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.gnav-search--main-content {
  .gnav-search__suggestions-wrapper {
    .typeahead-search__see-all-results {
      visibility: visible;
      height: auto;
      text-transform: none;
      border: 1px solid $color-drak-black;
      padding: 5px;
      .typeahead-suggestions__item {
        padding: 0px;
        a.typeahead-search__suggestion {
          cursor: pointer;
          display: inline-block;
          line-height: 30px;
          font-size: 15px;
          font-family: $font--futura-book;
          background-color: $color-drak-black;
          color: $color-white;
          padding: 0 15px;
          font-weight: 200;
          text-align: center;
          white-space: nowrap;
          width: 100%;
          height: 35px;
        }
      }
    }
  }
}

@if is_2020_nav_redesign() {
  .gnav-util--search .gnav-util__content {
    @include breakpoint($small-down) {
      position: static;
      padding: 0;
      margin: 0;
      opacity: 1;
      visibility: visible;
      border: none;
      height: auto;
      max-height: none;
    }

    .gnav-search--main-content {
      @include breakpoint($small-down) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: $color-white;
      }
    }
  }
}
